import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import PriceTable from '../components/PriceTable';
import FounderQuote from '../components/FounderQuote';
import ContactForm from '../components/ContactForm';
import Navigation from '../components/navigation';
import Chevron from '../components/Chevron';
import { Helmet } from 'react-helmet';
import SEO from '../components/seo';

import BlockContent from '@sanity/block-content-to-react';

const Homepage = ({ data, location }) => {
  const allCoachesImage = data.sanityHomepage.allCoachesImage;
  const trainingPlansImage = data.sanityHomepage.trainingPlansImage;
  const sanityHomepage = data.sanityHomepage;
  const founderQuote = data.sanityHomepage.founderQuote;

  const blockRenderer = ({ node, children }) => {
    switch (node.style) {
      case 'h1':
        return (
          <div>
            <h1 css={homePageTitleStyles}>{children}</h1>
          </div>
        );
      case 'h2':
        return (
          <div>
            <h2 css={homePageTitleStyles}>{children}</h2>
          </div>
        );
      case 'h3':
        return (
          <div>
            <h3 css={homePageTitleStyles}>{children}</h3>
          </div>
        );
      case 'h4':
        return (
          <div>
            <h4 css={homePageTitleStyles}>{children}</h4>
          </div>
        );
      case 'h5':
        return (
          <div>
            <h5 css={homePageTitleStyles}>{children}</h5>
          </div>
        );
      case 'h6':
        return (
          <div>
            <h6 css={homePageTitleStyles}>{children}</h6>
          </div>
        );
      case 'blockquote':
        return (
          <div>
            <blockquote>{children}</blockquote>
          </div>
        );
      default:
        return (
          <div>
            <p>{children}</p>
          </div>
        );
    }
  };

  const heroContainerStyles = css`
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
    overflow: hidden;
  `;

  const heroStyles = css`
    width: 100%;
    min-height: 70vh;

    img {
      position: relative;
      z-index: -1;
    }

    /* img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    } */
  `;

  const heroOverlay = css`
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(25, 35, 61, 0) 70%,
      rgba(25, 35, 61, 1) 100%
    );
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
  `;

  const homePageTitleStyles = css`
    font-size: var(--title-large);
    font-weight: 400;
    color: var(--colour-heading);
  `;

  const introTextStyles = css`
    max-width: 900px;
    margin: 0 var(--size-4);

    @media (min-width: 948px) {
      margin: 0 auto;
    }
  `;

  const founderQuoteStyles = css`
    margin: 80px var(--size-4);

    @media (min-width: 924px) {
      margin: 160px auto;
      max-width: 900px;
    }
  `;

  const trainingPlansStyles = css`
    max-width: 1020px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0 var(--size-4);

    @media (min-width: 600px) {
      margin: 0 var(--size-8);
    }

    @media (min-width: 1068px) {
      margin: 0 auto;
      flex-flow: row wrap;
      align-items: center;
    }
  `;

  const trainingPlansTextStyles = css`
    font-size: var(--title-large);
    flex: 0 0 100%;
    width: 100%;
    order: 2;
    @media (min-width: 768px) {
      flex: 0 0 40%;
      width: 40%;
      order: 1;
      margin-bottom: 0;
    }
  `;
  const trainingPlansImageStyles = css`
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: var(--size-8);
    order: 1;
    box-shadow: 16px 16px 24px 0 rgba(51, 51, 51, 0.16);

    @media (min-width: 768px) {
      width: calc(60% - var(--size-4));
      flex: 0 0 calc(60% - var(--size-4));
      margin-left: var(--size-4);
      margin-bottom: 0;
      order: 2;
    }
  `;

  const coachesStyling = css`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
    background: #162231;
    padding-top: var(--size-10);

    @media (min-width: 948px) {
      margin: 160px auto;
    }
  `;

  const coachesTextStyling = css`
    font-weight: 700;
    line-height: var(--line-height-heading);
    color: var(--colour-white);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;

    span {
      margin-bottom: var(--size-9);
      font-size: var(--size-2);
      font-weight: 600;
    }
  `;

  const coachesImageStyling = css`
    width: 100%;
    max-width: 900px;
  `;

  const pricingTableStyles = css`
    max-width: 840px;
    margin: 80px var(--size-4);

    @media (min-width: 840px) {
      margin: 160px auto;
    }
  `;

  return (
    <Layout location={location}>
      <Navigation />

      <div
        css={css`
          background: #fff;
          overflow: hidden;
          margin-bottom: var(--size-10);
        `}
      >
        <SEO />

        <Helmet>
          <script type='application/ld+json'>
            {`
        {
          "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Tri-Rivington",
            "url": "https://www.tri-rivington.co.uk",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.tri-rivington.co.uk/tri-rivington-logo.png"
          }
        }
      `}
          </script>
        </Helmet>

        <div css={heroContainerStyles}>
          <Img
            css={heroStyles}
            fluid={{
              ...sanityHomepage.heroImage.asset.fluid,
              sizes:
                '(min-width: 800px) 800px, (min-width: 1200px) 1680px, 100vw',
            }}
          />
          <div css={heroOverlay}></div>
        </div>

        <div css={introTextStyles}>
          {/* {documentToReactComponents(introText.json, options)} */}
          <BlockContent
            blocks={sanityHomepage._rawIntroText}
            serializers={{
              types: { block: blockRenderer },
            }}
          />
        </div>

        <div css={founderQuoteStyles}>
          <FounderQuote
            image={founderQuote.image.asset}
            quote={founderQuote.quote}
            name={founderQuote.person}
          />
        </div>

        <div css={trainingPlansStyles}>
          <div css={trainingPlansTextStyles}>
            <h2
              css={css`
                margin-bottom: calc(var(--size-1) * 0.5);
                font-size: var(--title-large);
              `}
            >
              Personalised Training Plans
            </h2>
            <div
              css={css`
                font-size: var(--size-4);
                margin-bottom: var(--size-1);
              `}
            >
              On your schedule, fit around your life.
            </div>

            <Link
              css={css`
                font-size: var(--size-2);
                display: block;
              `}
              to='/training-plans'
            >
              <span
                css={css`
                  display: inline-block;
                  font-size: var(--size-2);
                  font-weight: 600;
                `}
              >
                Learn about training plans
              </span>
              <Chevron colour='var(--colour-blue)' />
            </Link>
          </div>
          <div css={trainingPlansImageStyles}>
            <Img
              fluid={{
                ...trainingPlansImage.asset.fluid,
                sizes: '(min-width: 1200px) 1150px, 100vw',
              }}
            />
          </div>
        </div>

        <div css={coachesStyling}>
          <div css={coachesTextStyling}>
            <h2
              css={css`
                font-size: var(--title-large);
              `}
            >
              Coaches with experience.
            </h2>

            <Link
              css={css`
                color: var(--colour-white);
              `}
              to='/meet-the-coaches'
            >
              <span
                css={css`
                  display: inline-block;
                `}
              >
                Meet the coaches
              </span>
              <Chevron />
            </Link>
          </div>

          <div css={coachesImageStyling}>
            <Img
              fluid={{
                ...allCoachesImage.asset.fluid,
                sizes: '(min-width: 1200px) 1800px, 100vw',
              }}
            />
          </div>
        </div>

        <div css={pricingTableStyles}>
          <PriceTable />
        </div>

        <ContactForm location={location.pathname} />

        {/* <Hero data={author.node} /> */}
        {/* <div className='wrapper'>
          <h2 className='section-headline'>Recent articles</h2>
          <ul className='article-list'>
            {posts.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
    </Layout>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }

    sanityHomepage(id: { eq: "-e07ca5b6-bcc6-5d90-8489-39702cd11394" }) {
      title
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawIntroText
      founderQuote {
        quote
        person
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
      trainingPlansImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      allCoachesImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
  }
`;
